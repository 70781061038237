/* .mainForm > section {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.mainForm select,
textarea,
input {
  display: block;
  background-color: #fff;
  color: var(--coal-black);
  border: 1px solid var(--light-gray2);
  border-radius: 0.8rem;
  padding: 1rem;
  margin-top: 0.6rem;
  margin-bottom: 2.6rem;
}

input {
  caret-color: var(--gold);
}

.mainForm input:focus,
.mainForm select:focus,
.mainForm textarea:focus {
  outline: none;
}

.formWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.4rem;
  padding: 8rem;
  padding-bottom: 12rem;
  justify-content: space-between;
}

/* .formWrapper h2 {
  font-weight: 400;
  font-size: 2.3rem;
  margin-bottom: 4rem;
} */

.formWrapper label {
  display: flex;
  width: 48%;

  flex-direction: column;
  background-color: transparent;
}

.formWrapper label:last-of-type {
  align-self: center;
  justify-self: center;
}

.formWrapper span {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.formWrapper .btnGold,
.formWrapper .btnOutline {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.formWrapper .message {
  width: 100%;
}

.fileUpload {
  display: flex;
  padding: 0;
}

::-webkit-file-upload-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--gold);
  font-weight: 500;
  background-color: #fff;
  border: 0;
  border-right: 1px solid var(--light-gray2);
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  margin-right: 1rem;
  padding: 1rem;
  transition: 0.3s;
}

::-webkit-file-upload-button:hover {
  background-color: var(--gold);
  color: #fff;
}
::-webkit-calendar-picker-indicator {
  display: inline-block;
  filter: invert(1);
}

.adDescription {
  min-height: 20rem;
}

.formMsgInfo {
  font-size: 1.4rem;
  color: var(--gray2);
}

.submittedMsg {
  text-align: center;
}

.submittedMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#complaintForm .wrapper,
#contactForm .wrapper {
  width: 100%;
  justify-content: space-around;
}

#complaintForm .text,
#contactForm .text {
  width: 48%;
}

/* .submittedMsg .text {
  padding: 1rem;
  background-color: #e0f1d9;
  color: #678871;
} */

/* .submittedMsg .btnGold {
  display: inline-block;
  margin: 2rem;
} */

@media (max-width: 992px) {
  .formWrapper {
    flex-direction: column;
  }

  /* #complaintForm .text,
  #contactForm .text {
    width: 100%;
  } */

  #complaintForm .wrapper,
  #contactForm .wrapper {
    flex-direction: row;
  }

  .formWrapper label {
    width: 100%;
  }
}

@media (max-width: 768) {
  #complaintForm .btnOutline,
  #contactForm .btnOutline {
    text-align: center;
  }
}

@media (max-width: 567px) {
  #complaintForm .text,
  #contactForm .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #complaintForm .text:nth-of-type(2) p:last-of-type,
  #complaintForm .text:nth-of-type(2) p:last-of-type {
    text-align: center;
  }

  .formWrapper {
    padding: 3rem;
  }
}
