/* .container {
    max-width: 1200px;
    margin: 60px auto;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    padding: 1rem 2rem 2rem 2rem;
  }
   */
/* .question {
  font-weight: 700;
} */

.list {
  padding: 0px;
  list-style: none;
  display: flex;
}

.listItem {
  margin-right: 2rem;
}
.inputQuiz[type="radio"] {
  display: none;
}
.inputText {
  align-items: center;
  display: flex;
  font-size: 2rem;
  margin-right: 2rem;
}
.inputQuiz[type="radio"] + .inputText::before {
  content: " ";
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  margin-right: 0.75rem;
  position: relative;
  border-radius: 3px;
  border: 1px solid #ccc;
  left: 0;
  cursor: pointer;
}

.inputQuiz[type="radio"]:checked + span::before {
  content: "X";
  font-weight: 800;
  color: #918a69;
}
/* 
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 500;
  min-height: 3.125rem;
  line-height: normal;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  margin: 0 1.5rem 0 0;
  background: #fff;
  border: 1px solid #eaeaea;
  color: #4a4a4a;
  cursor: pointer;
  transition: all 1s;
} */

#verificationBorrowersCapabilities .btnGold,
#verificationBorrowersCapabilities .btnOutline {
  padding: 1.6rem 2rem;
  min-width: 10rem;
}

#verificationBorrowersCapabilities .bold {
  margin-bottom: 4rem;
}

#verificationBorrowersCapabilities h3 {
  margin-bottom: 2rem;
}

/* .buttonDark {
  background-color: #918a69;
  border-color: #918a69;
  color: #fff;
  margin-left: auto;
} */

/* .button:hover {
  background: #736e54;
  border-color: #736e54;
  color: #fff;
} */

.textRed {
  color: red;
}

.textGreen {
  color: green;
}

.buttonWrapper {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
}
