#cookies {
  margin-bottom: 15vh;
  max-width: 100%;
}

.cookiesText {
  font-size: 2rem;
}

.cookiesText p {
  margin-bottom: 3rem;
}

.cookiesText ul {
  padding-left: 2rem;
}

.uberCookieList > li {
  list-style: decimal;
  margin-bottom: 2rem;
}

.minorCookieList > li {
  font-size: 1.8rem;
  /* list-style: disc; */
  margin-bottom: 1.2rem;
}

.minorCookieList > li:nth-last-child(1) {
  margin-bottom: 2rem;
}

/* .cookiesText ::marker {
  display: inline-block;
  font-weight: bold;
  color: var(--gold);
} */
