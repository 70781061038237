#home {
  margin-top: 0;
  padding-top: 0;
}

/* SECTION hero */

#hero {
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../img/gnbbank-HOME-header(2025x1080\).webp");
  min-width: 100vw;
  min-height: 100vh;
  padding-top: 10rem;
  background-position: center center;
  background-size: cover;
}

.heroHeading {
  display: flex;
  padding: 10rem 0;
  padding-left: 15vw;
}

.headingText {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
}

.headingText h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 3.6rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 2rem;
}

.headingText p {
  font-size: 2.8em;
  font-weight: 300;
  color: var(--light-gray);
  margin-bottom: 3rem;
}

.headingText a {
  display: inline-block;
}

/* SECTION services */

#services .serviceCards .card {
  width: 48%;
  gap: 2rem;
}

@media (max-width: 1200px) {
  .headingText {
    width: 65%;
  }
}

@media (max-width: 992px) {
  .headingText {
    width: 70%;
    padding-left: 0;
  }

  #services .serviceCards {
    display: flex;
    flex-direction: column;
  }

  #services .serviceCards .card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .heroHeading {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 0;
  }

  .headingText {
    width: 70%;
  }

  .headingText h1 {
    font-size: 3.6rem;
    margin-bottom: 4rem;
  }

  .headingText p {
    margin-bottom: 4rem;
  }
  .headingText h1 {
    font-size: 3.2rem;
  }

  .headingText p {
    font-size: 2.4rem;
  }
}

@media (max-width: 375px) {
  .headingText h1 {
    font-size: 2.8rem;
  }

  .headingText p {
    font-size: 2.2rem;
  }
}
