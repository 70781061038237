.accordionBox {
  border: 1px solid var(--gold);
  border-radius: 10px;
}

.accordion {
  transition: 0.2s;
  border-bottom: 1px solid var(--gold);
}

.accordion:nth-of-type(1) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.accordion:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: none;
}

.accordion button {
  display: flex;
  font-family: "Poppins";
  width: 100%;
  background-color: transparent;
  color: var(--coal-black);
  border: none;
  padding: 3.5rem;
  font-size: 2.4rem;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  transition: padding-left 0.4s;
}
.accordionButton:hover {
  background-color: #fff;
  padding-left: 6rem;
  color: var(--gold);
  border-radius: 10px;
}
.accordionButton.active {
  padding-left: 6rem;
  background-color: #fff;
  color: var(--gold);
}

.accordionButton span {
  margin: 0 1.6rem;
  display: block;
  transition: 0.3s;
  width: 2rem;
  height: 4rem;
  font-size: 4rem;
  line-height: 4rem;
  text-align: right;
}

.accordionIcon {
  transition: 0.1s;
}

.accordionIcon.rotate {
  transform: rotate(45deg);
}

.content {
  font-size: 2rem;
  background-color: #fff;
  max-height: 0; /* Początkowo ukryta treść */
  overflow: hidden; /* Ukrywanie zawartości poza max-height */
  transition: max-height 2s linear; /* Transition z opóźnieniem */
}

.content:nth-last-of-type(1) {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content.active {
  border-top: 1px solid var(--gold);
  padding: 3rem;
  max-height: 100%; /* Dostatecznie duża wartość, aby pokazać całą treść */
}

.content p {
  opacity: 0;
  transition-delay: 0.4s;
  transition: 0.5s;
}

.content.active p {
  opacity: 1;
}

.content ul {
  padding-left: 2rem;
}

/* .content ul > li {
  list-style: disc;
  margin-bottom: 1.2rem;
} */

.content li::marker {
  display: inline-block;
  color: var(--gold);
}

/* .content:nth-of-type(1) .active {
  max-height: 400px;
}
.content:nth-of-type(2) .active {
  max-height: 550px;
}
.content:nth-of-type(3) .active {
  max-height: 350px;
}
.content:nth-of-type(4) .active {
  max-height: 500px;
}
.content:nth-of-type(5) .active {
  max-height: 600px;
}
.content:nth-of-type(6) .active {
  max-height: 1600px;
}
.content:nth-of-type(7) .active {
  max-height: 1000px;
}
.content:nth-of-type(8) .active {
  max-height: 2200px;
}
.content:nth-of-type(9) .active {
  max-height: 1900px;
} */

@media (max-width: 992px) {
  .accordion button {
    font-size: 2rem;
  }

  .content {
    font-size: 1.7rem;
  }
}
