/* #header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
}

.mobilePopup {
  display: none;
}

.maintenanceBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--coal-black);
  color: #fff;

  padding: 2rem 6rem;
  font-size: 1.7rem;
  color: #fff;
  z-index: 100;
}

.maintenanceBanner i {
  margin-left: 2rem;
  font-size: 4rem;
}

.maintenanceBanner p {
  margin: 0;
} */

#menu,
#logo {
  transition: 0.2s;
}

#menu {
  position: relative; /* to trzeba zakomentować jak jest baner */
  display: flex;
  width: 100vw;
  align-items: center;
  position: fixed; /* to trzeba zakomentować jak jest baner */
  top: 0; /* to trzeba zakomentować jak jest baner */
  z-index: 1000;
  background-color: transparent;
  padding: 0 10vw;
  scroll-margin-top: 440px;
}

#menu.active {
  background-color: #fff;
  color: var(--coal-black);
  box-shadow: 0px 0px 24px -2px rgba(169, 169, 169, 1);
}

.navOption a {
  color: #fff;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  text-align: center;
  transition: 0.2s;
  /* overflow: hidden; */
}

/* .banking {
  border: 1px solid #fff;
  font-size: 1.8rem;
  border-radius: 4px;
  padding: 1rem 1.4rem;
} */

.navOption a.active {
  color: var(--coal-black);
}
.navOption a:hover {
  color: var(--gold);
  border-color: var(--gold);
}

.navOption a:active {
  color: var(--gold);
}

.navLogo {
  z-index: 100;
  padding: 2rem;
}

.navLogo img {
  min-width: 120px;
  max-width: 120px;
}

.navOptions {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2.2rem;
}

.navOptions li {
  display: flex;
  align-items: center;
  margin: 0 4.8rem;
}

.navOption a {
  font-family: "Open Sans", "Arial", sans-serif;
  text-transform: uppercase;
}

.navLogo,
.navOption a {
  cursor: pointer;
}

.fontSizeControls {
  display: flex;
  align-items: end;
  gap: 1rem;
  position: absolute;
  top: 10px; /* to trzeba zakomentować jak jest baner */
  /* top: 80px; */ /* to trzeba odkemtnować jak jest baner */
  right: 13vw;
  /* z-index: 100; */
}

.fontSizeControls a {
  background: transparent;
  /* color: #fff; */
  border: none;
}

.fontSizeControls .firstFontChanger {
  font-size: 14px;
}
.fontSizeControls .secondFontChanger {
  font-size: 17px;
}
.fontSizeControls .thirdFontChanger {
  font-size: 20px;
}

.fa-bars,
.fa-xmark {
  display: none;
}

.mobileNav {
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
}

/* MAX WIDTH */

@media (max-width: 1118px) {
  #menu {
    flex-direction: column;
    align-items: flex-start;
    top: 0px;
  }

  .fa-bars,
  .fa-xmark {
    position: fixed;
    display: block;
    top: 27px;
    height: 36px;
    right: 12vw;
    /* z-index: 100; */
    transition: 0.2s;
  }

  .mobileNav.active {
    height: 100vh;
    position: fixed;
    background-color: #fff;
    z-index: -1;
    box-shadow: 0px 0px 24px -2px rgba(169, 169, 169, 1);
  }

  .navOptions {
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    /* display: none; */
    /* padding-top: 10vh; */
    /* left: -50%; */
    /* top: 50%; */
    left: 0;
    opacity: 0;
    transform: translateY(50%);
    transition: 1s;
    /* transition-delay: 0.1s; */
  }

  .navOption {
    display: flex;
  }

  .navOption a {
    color: var(--coal-black);
    font-size: 2.5rem;
    font-weight: 400;
    padding: 3rem 0;
    text-align: left;
  }

  .navOptions li {
    margin: 0 12vw;
  }

  .navOptions.active {
    display: flex;
    opacity: 1;
  }

  .navLogo {
    transition: 0;
  }

  .fontSizeControls {
    display: none;
  }

  .maintenanceBanner {
    display: none;
  }

  .mobilePopup {
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(34, 34, 34, 0.95);
    width: 50%;
    padding: 5rem;
    color: #fff;
    border-radius: 3rem;
    z-index: 101;
    text-align: center;
  }

  .mobilePopup i {
    margin-bottom: 2rem;
  }

  .mobilePopup .buttonContainer {
    display: flex;
    justify-content: center;
  }

  .mobilePopup .btnConfirm {
    display: block;
    padding: 1.4rem 2.2rem;
    align-self: center;
    margin-top: 2rem;
  }

  .mobilePopup i {
    margin-left: 2rem;
    font-size: 4rem;
  }

  .mobilePopup p {
    margin: 0;
  }
}

@media (max-width: 992px) and (max-height: 1024px) and (min-height: 834px) {
  .mobileNav.active {
    height: 80vh;
  }
}

@media (max-width: 992px) and (min-height: 1024px) {
  .mobileNav.active {
    height: 60vh;
  }
}

@media (max-width: 568px) {
  .navLogo img {
    min-width: 120px;
    max-width: 120px;
  }

  .mobilePopup {
    width: 80%;
  }
}

@media (max-width: 374px) {
  .navOptions li {
    margin: 0 2rem;
  }
  .navOption a {
    font-size: 2.2rem;
  }
}

/* MIN WIDTH */

@media (min-width: 992px) and (max-width: 1200px) {
  .navOption a {
    font-size: 1.7rem;
  }
}

@media (min-width: 1600px) {
  .navLogo img {
    min-width: 150px;
    max-width: 150px;
  }
}

/* MAX HEIGHT */

@media (max-height: 568px) {
  .mobileNav.active {
    height: 100vh;
  }

  .navOption a {
    padding: 2rem 0;
  }
}

@media (max-height: 374px) {
  .mobileNav.active {
    height: 100vh;
  }

  .navOptions {
    padding-top: 0;
  }

  .navOption a {
    font-size: 2.2rem;
    padding: 1rem 0;
  }
}
