:root {
  --coal-black: #222222;
  --text-black: #444444;
  --gray1: #868575;
  --gray2: #666;
  --light-gray: #dddddd;
  --light-gray2: #c1c1c1;
  --very-light-gray: #ebebeb;
  /* --gold: #ab8249; */
  --light-gold: #a6a795;
  /* --gold: #9c925a; */
  --gold: #918a69;
  --red: #d54b4f;
  --background: #f3f3f3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* remove default spacing */
/* force styling of type through styling, rather than elements */

* {
  margin: 0;
  padding: 0;
  font: inherit; /* dark mode user-agent-styles */
  font-family: "Open Sans", "Arial", sans-serif;
}

html {
  font-size: 62.5%; /* 10px as base size */
  font-size: 8px;
  transition: 0.2s;
  color: var(--text-black);
  background-color: var(--background);
  overflow-x: hidden;
  color-scheme: dark light;
  scroll-padding-top: 50px;
  /* scroll-behavior: smooth; */
}

/* min body height */

body {
  min-height: 100vh;
}

main {
  /* margin: 0 0 7.5vh 0; */ /* to trzeba odkomentować jak jest baner */
  /* padding-top: 200px; */ /* to trzeba odkomentować jak jest baner */
  margin: 130px 0 7.5vh 0;
  min-height: 50vh;
}

/* responsive images/videos */
img,
picture,
video {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--coal-black);
}

li {
  list-style: none;
  /* padding: 1rem 0; */
  font-size: 2rem;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.bottomLine {
  padding: 1.2rem 0;
  border-bottom: 1px solid var(--light-gray2);
}

.bottomLine:nth-last-of-type(1) {
  border: none;
}

.dottedList {
  padding-left: 2rem;
}

.numberList {
  padding-left: 2.5rem;
}

.dottedList > li {
  list-style: disc;
  margin-bottom: 1rem;
}

.dottedList ::marker,
.numberList ::marker {
  display: inline-block;
  font-weight: bold;
  color: var(--gold);
}
.numberList ::marker {
  font-family: "Poppins", sans-serif;
  font-size: 2.4rem;
}

.numberList > li {
  list-style: decimal;
  margin-bottom: 1rem;
}
.numberList {
  list-style: decimal;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: var(--coal-black);
}

/* .headerBg h1 {
  font-size: 4rem;
  color: #fff;
} */

h1.heading {
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 6rem;
}

h2 {
  display: inline;
  position: relative;
  display: inline-block;
  color: var(--coal-black);
  font-family: "Poppins", sans-serif;
  font-size: 3.2rem;
  line-height: 5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5rem;
  /* text-decoration: 4px underline var(--gold);
  text-underline-offset: 10px; */
}

h2::after {
  content: "";
  position: absolute;
  bottom: -4px;
  right: 0;
  width: 100%;
  height: 4px;
  border-bottom: 4px solid var(--gold);
}

h3 {
  font-size: 2.7rem;
  font-family: "Poppins", sans-serif;
  color: var(--coal-black);
  font-weight: 500;
}

.larger {
  font-size: 2.5rem;
}

.white {
  color: #fff;
  font-weight: 400;
}

.text h3 {
  margin-bottom: 2rem;
}

/* .whiteH {
  color: #fff;
  font-weight: 400;
} */

.text {
  margin-bottom: 6rem;
}

.textDoubleMargin {
  margin-bottom: 12rem;
}

p {
  font-size: 2rem;
  margin-bottom: 1.6rem;
}

label {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}

select {
  max-width: 100%;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border: 1px solid var(--gray1);
  color: var(--coal-black);
  outline: none;
  background-color: transparent;
}

.container {
  margin-left: 15vw;
  margin-right: 15vw;
  padding-top: 7.5vh;
  padding-bottom: 7.5vh;
  /* margin: 7.5vh 15vw 7.5vh 15vw; /* top right bottom left */
}

.whiteBgc {
  border-radius: 14px;
  padding: 5rem;
  background-color: #fff;
  /* box-shadow: 0px 0px 12px 0px rgb(216, 216, 216); */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapperCol {
  width: 48%;
}

.sectionHeader {
  display: flex;
}

a.gold {
  color: var(--gold);
}

.rounded {
  border-radius: 6px;
}

.border {
  border: 1px solid var(--gold);
  padding: 1.5rem;
}

.border p:last-of-type {
  margin: 0;
}

.flexbox {
  display: flex;
}

.square {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px;
  align-self: center;
  text-align: center;
  background-color: var(--light-gray);
  margin-right: 2rem;
}

.square p {
  font-size: 6rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.shadow {
}

.btnGold,
.btnOutline {
  padding: 2rem 3.2rem;
  font-size: 2rem;
  /* letter-spacing: 0.1rem; */
  border-radius: 10px;
  cursor: pointer;
}

.btnGold {
  color: #fff;
  border: none;
  /* background-image: linear-gradient(
    135deg,
    rgb(195, 181, 115) 0%,
    rgb(179, 167, 104) 20%,
    rgba(157, 102, 24, 1) 100%
  ); */

  /* background-image: linear-gradient(
    135deg,
    rgb(214, 202, 142) 0%,
    rgb(179, 167, 104) 20%,
    rgb(98, 91, 56) 100%
  ); */
  /* 
    background-image: linear-gradient(
    135deg,
    rgb(195, 181, 115) 0%,
    rgb(179, 167, 104) 20%,
    rgb(123, 110, 57) 100%
  ); */

  background-image: linear-gradient(
    160deg,
    rgb(176, 168, 127) 0%,
    rgb(103, 92, 47) 100%
  );

  background-size: 100%;
  background-position: right;
  transition: background-size 0.4s, color 0.1s;
}

.btnGold:hover {
  background-size: 300%;
}

.btnGold:active {
  color: var(--coal-black);
}

.btnOutline {
  background-color: transparent;
  border: 1px solid var(--gold);
  color: var(--coal-black);
  transition: 0.2s;
}

.btnOutline:hover {
  background-color: var(--background);
}

.serviceCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.serviceCards .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.serviceCards h3 {
  margin-bottom: 2rem;
}

.serviceCards a {
  font-size: 1.8rem;
}

.card {
  padding: 3rem;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  transition: 0.5s;
  border-radius: 10px;
}

.additionalMargin {
  margin: 4rem 0;
}

.gridParent {
  display: grid;
  /* grid-auto-rows: 1fr; */
  background-color: var(--background);
  font-size: 2rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 3rem 0;
  align-items: center;
  text-align: center;
  transition: 0.3s;
}
.gridParent:nth-of-type(odd) {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%
  );
  /* border-top: 1px solid var(--light-gray2); */
  /* border-bottom: 1px solid var(--light-gray2); */
}

/* .headerBg {
  background-position: center center;
  background-size: cover;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-top: 10rem;
  padding-bottom: 10rem;
} */

.imageBackground {
  min-height: 400px;
  max-height: 900px;
  background-size: cover;
  border-radius: 10px;
  padding: 5rem 7rem;
}

.bottomShape {
  transform: rotate(180deg);
}

.shapeOne {
  fill: transparent;
}

.shapeTwo {
  fill: #fff;
  opacity: 0.5;
}

.shapeThree {
  fill: #fff;
  opacity: 0.25;
}

/* MEDIA QUERIES MAXIMUM */

@media (min-width: 1800px) {
  html {
    font-size: 9px;
  }
}

/* @media (max-width: 1800px) {
  html {
    font-size: 8px;
  }
} */

@media (max-width: 1800px) {
  main {
    /* margin-top: 170px; */ /* to trzeba odkomentować jak jest baner */
    /* padding: 0; */ /* to trzeba odkomentować jak jest baner */
    margin-top: 100px; /* to trzeba zakomentować jak jest baner */
  }
}

@media (max-width: 1118px) {
  main {
    margin: 100px 0 7.5vh 0;
  }

  .container {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .imageSection .container {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
  }

  .imageBackground {
    width: 100vw;
    border-radius: 0;
  }
}

@media (max-width: 992px) {
  /* .container {
    margin-left: 15vw;
    margin-right: 15vw;
  } */

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .wrapperCol {
    width: 100%;
  }

  .wrapperCol:nth-of-type(1) {
    margin-bottom: 8rem;
  }
}

@media (max-width: 768px) {
  h1.heading {
    text-align: center;
  }
}

@media (max-width: 567px) {
  .container {
    margin-left: 7vw;
    margin-right: 7vw;
  }

  .sectionHeader {
    justify-content: center;
  }

  h2 {
    font-size: 2.5rem;
    /* text-underline-offset: 8px; */
  }
}

/* MEDIA QUERIES MINIMUM */
@media (min-width: 1200px) {
  .shadow:hover {
    box-shadow: 0px 0px 12px 0px rgba(169, 169, 169, 1);
  }
}

@media (min-width: 567px) and (max-width: 1118px) {
  .headerBg {
    padding-left: 10vw;
  }
}
