#debtConclude .imageBackground {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../img/gnbbank-DebtRestructuring-background.webp");
  background-position: center top;
}

/* #debtConclude h2 {
  font-size: 2.5rem;
} */

#debtConclude .textFrame {
  background-color: rgb(255, 255, 255, 0.9);
  width: 50%;
  padding: 5rem;
}

#debtConclude h3 {
  margin-bottom: 5rem;
}

/* #chooseDebtFormula .wrapper {
  margin-bottom: 8rem;
}

#chooseDebtFormula .wrapper button {
  width: 48%;
} */

#chooseDebtFormula h3 {
  margin-bottom: 5rem;
}

#chooseDebtFormula select {
  margin-bottom: 5rem;
}

#chooseDebtFormula .btnOutline {
  margin-left: 2rem;
  padding: 1.5rem;
}

@media (max-width: 1700px) {
  #debtConclude .textFrame {
    width: 60%;
  }
}

@media (max-width: 1400px) {
  #debtConclude .textFrame {
    width: 70%;
  }
}

@media (max-width: 1280px) {
  #debtConclude .textFrame {
    width: 80%;
  }
}

@media (max-width: 1118px) {
  #debtConclude .textFrame {
    width: 100%;
  }

  #debtConclude .container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 567px) {
  #debtConclude .imageBackground {
    padding: 5rem 7vw;
  }
}

@media (min-width: 568px) and (max-width: 768px) {
  #debtConclude .sectionHeader h2 {
    margin-left: 10vw;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #debtConclude .sectionHeader h2 {
    margin-left: 15vw;
  }
}
