.cookiesBanner {
  display: flex;
  justify-content: space-between;
  position: fixed;
  max-width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2.5rem 4rem;
  background-color: rgb(34, 34, 34, 0.7);
  font-size: 1.7rem;
  color: #fff;
  z-index: 100;
}

.cookiesBanner h4 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.cookiesBanner p {
  color: var(--very-light-gray);
}

.cookiesBanner .underline {
  color: #fff;
}

.closeBtn {
  cursor: pointer;
  min-width: 4rem;
  font-size: 3rem;
}
