/* SECTION Alerts */

.cards {
  font-size: 1.6rem;
}

#newAlerts .goToAlerts {
  display: block;
  text-align: end;
  line-height: 2rem;
}
#newAlerts .goToAlerts a,
.fa-chevron-right {
  font-size: 1.8rem;
  color: var(--gold);
}

.fa-chevron-right,
.goToAlerts span {
  transition: 0.4s;
}

.fa-chevron-right {
  padding-left: 1rem;
  padding-right: 0.6rem;
  cursor: pointer;
}

#newAlerts .goToAlerts a:hover .fa-chevron-right {
  padding-right: 0;
}
#newAlerts .goToAlerts a:hover span {
  padding-right: 0.6rem;
}

/* SECTION INFORMATION */

#information h3 {
  /* font-weight: 500; */
  /* font-size: 2.2rem; */
  margin-bottom: 3rem;
}

#information p {
  /* font-size: 2rem; */
  margin-bottom: 1.4rem;
}

#information p:last-of-type {
  margin-bottom: 0;
}

/* SECTION DOCUMENTS */

#documents {
  font-size: 2.2rem;
}
#documents li {
  margin-bottom: 3.5rem;
}

#alerts .larger {
  text-align: center;
  margin: 8rem;
}

/* SECTION Trustee Contact */

#trusteeContact svg {
  color: var(--gold);
  font-size: 2.8rem;
  padding-right: 2rem;
}

#trusteeContact span {
  display: block;
  margin-top: 1rem;
  font-size: 2.2rem;
}

#trusteeContact .flexbox {
  align-items: center;
  margin: 2rem 0;
}
#trusteeContact .flexbox:last-of-type {
  margin-top: 0;
}

#trusteeContact a {
  display: block;
  font-size: 2.4rem;
}

@media (max-width: 568px) {
  #bankruptcy #documents ul {
    padding-left: 2rem;
  }
}
