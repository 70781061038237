.alertCard {
  display: flex;
  border-radius: 1.4rem;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 30rem;
  padding: 3rem;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  font-size: 1.8rem;
  transition: 0.5s;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 600;
}

.cardText {
  margin-bottom: 2rem;
}

.cardText p {
  margin-bottom: 1rem;
}

.cardText p:last-of-type {
  margin-bottom: 0;
}
.cardDate {
  margin-top: 1rem;
  width: 100%;
  text-align: end;
}

.readMore {
  display: block;
  margin-top: 20px;
  color: var(--gold);
  cursor: pointer;
}

/* MEDIA QUERIES MINIMUM */

/* MEDIA QUERIES MAXIMUM */

/* @media (max-width: 1200px) {
  .alertCard {
    font-size: 2rem;
  }
} */

/* @media (max-width: 992px) {
  .alertCard {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .alertCard {
    font-size: 2rem;
  }
} */
