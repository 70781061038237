/* #documents {
  font-size: 2.2rem;
}
#documents li {
  margin-bottom: 3.5rem;
}

#documents ul li::before {
  content: "\2022";
  color: var(--gold);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media (max-width: 568px) {
  #documents ul {
    padding-left: 2rem;
  }
} */

#documents a {
  display: block;
}

#documents .docPanel {
  display: flex;
  align-items: center;
}

#documents .docPanel svg {
  margin-right: 2rem;
  width: 3rem;
  height: 3rem;
  color: var(--gold);
}

#documents p span {
  color: var(--gray1);
  font-size: 1.6rem;
}

#documents h3 {
  margin: 6rem 0 2rem 0;
}

#documents .searchBar input {
  width: 100%;
  padding: 2rem;
  border: 1px solid var(--gold);
  margin-bottom: 6rem;
}
