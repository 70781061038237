#borrowerSupportFund .imageBackground {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../img/gnb-rodo-bg-2400x900px.webp");
  display: flex;
  align-items: center;
}
#borrowerSupportFund .headerBg h1 {
  color: #fff;
  font-weight: 400;
}

#aboutBorrowerSupportFund ul {
  margin-top: 1.2rem;
}

/* #verificationBorrowersCapabilities {
  background-color: #fff;
} */

/* @media (max-width: 1118px) {
  #borrowerSupportFund > section .container {
    margin-left: 0;
    margin-right: 0;
  }
} */

@media (max-width: 768px) {
  #borrowerSupportFund .imageBackground {
    background-position: 50% 0;
  }
}

@media (max-width: 567px) {
  #borrowerSupportFund .imageBackground {
    padding: 5rem 7vw;
  }
}
