#exchangeRates .gridParent:hover {
  background: #e6f0e3;
}

#exchangeRates .gridParent:nth-of-type(1) {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%
  );
  font-weight: 500;
}

/* #exchangeRates .gridParent:nth-of-type(2), {
  border-top: 1px solid var(--light-gray2);
  border-bottom: 1px solid var(--light-gray2);
}

#exchangeRates .gridParent:nth-of-type(3) {
  border-bottom: 1px solid var(--light-gray2);
} */

.currencyTab {
  margin: 5rem 0;
}

#exchangeRates .gridParent {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}

#exchangeRates .gridParent .i1 {
  font-weight: 500;
  text-align: left;
  padding-left: 2rem;
}

#exchangeRates .currencyTab:nth-child(1) {
  font-weight: 700;
}

/* FOR NOBLE BANK */

#exchangeRates.nobleCredits .gridParent {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
}

#exchangeRates.nobleCredits .currencyTab.active .gridParent {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}

#exchangeRates.nobleCredits .switchTable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 5rem 0;
}

#exchangeRates.nobleCredits .switchTable a {
  font-size: 2.2rem;
  transition: 0.2s;
  margin-bottom: 1rem;
  /* margin-left: 5rem; */
}

#exchangeRates.nobleCredits a:first-of-type {
  margin-right: 5rem;
}

#exchangeRates.nobleCredits a.active {
  color: var(--gold);
}

#exchangeRates.nobleCredits a:hover {
  color: var(--gold);
  cursor: pointer;
}

@media (max-width: 767px) {
  #exchangeRates.nobleCredits .currencyTab.active .gridParent {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }

  #exchangeRates.nobleCredits .gridParent {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }
}

@media (max-width: 440px) {
  /* #exchangeRates .currencyTab li {
    font-size: 14px;
  } */

  #exchangeRates.nobleCredits .currencyTab li {
    font-size: 18px;
  }

  #exchangeRates.nobleCredits .switchTable {
    width: 100%;
    justify-content: space-between;
  }

  #exchangeRates .gridParent {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }

  #exchangeRates.nobleCredits .currencyTab li.i1 {
    margin-bottom: 1rem;
  }

  #exchangeRates.nobleCredits a:first-of-type {
    margin-right: 0;
  }

  #exchangeRates.nobleCredits .gridParent {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
  }
}
