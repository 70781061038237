#help .wrapper {
  justify-content: left;
  flex-wrap: nowrap;
  gap: 5rem;
}

#help .card {
  max-width: 50rem;
}

#help .card:nth-of-type(2) h3 {
  color: #fff;
}

#help .card:nth-of-type(2) {
  color: #fff;
  background-image: linear-gradient(
    135deg,
    rgb(176, 170, 139) 0%,
    rgb(123, 110, 57) 100%
  );
}

#help .btnOutline {
  display: inline-block;
  background-color: #fff;
}

#help .btnOutline a {
  transition: 0.2s;
}

#help .btnOutline a:hover {
  color: var(--gold);
}

@media (max-width: 992px) {
  #help {
    display: flex;
    justify-content: center;
  }
}
