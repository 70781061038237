#contact a {
  font-size: 2rem;
}

#contact .phoneBig {
  display: block;
  font-size: 4rem;
  margin: 2rem 0;
}

#contact .btnGold {
  margin: 2rem 0;
}

#contact #debtRestructuring .btnGold {
  margin-top: 4rem;
}

/* #address h2,
#address h3 {

} */

.addressHeadquaters {
  margin: 1.8rem 0;
  font-size: 2.5rem;
}

#address .container {
  padding-top: 0;
}

#address .imageBackground {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../img/gnbbank-CONTACT-background(2025x1080\).webp");
  background-position: center bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#complaint .text {
  margin: 2rem 0 4rem 0;
}

#complaint a:nth-last-of-type(1) {
  font-size: 2.5rem;
}

@media (max-width: 660px) {
  #contact .buttonContainer {
    justify-content: center;
    text-align: center;
  }
}
