footer {
  position: relative;
  /* display: flex; */
  width: 100%;
  padding: 0 10vw;
  background-color: var(--coal-black);
  color: var(--light-gray);
  font-size: 2rem;
  font-weight: 300;
  /* margin-top: 15vh; */
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
}

.footerCompanyDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10rem 0;
}

.footerDetail a {
  color: var(--light-gray);
}

.footerCompanyDetails li {
  margin: 1.6rem 0;
}

/* .footerDetail span {
  width: 80%;
} */

.stCol .footerLogo {
  width: 180px;
  margin-bottom: 2rem;
}

.stCol i {
  margin-right: 1rem;
}

.stCol {
  width: 35%;
}

.ndCol {
  width: 15%;
}

.rdCol {
  width: 25%;
}

.ndCol li {
  margin: 3rem 0;
}

.ndCol a {
  color: var(--light-gray);
}

.ndCol a:active {
  color: var(--yellow);
}

.footerCopyright {
  width: 100%;
  padding: 0.7rem 0;
  text-align: end;
  border-top: 1px solid var(--light-gold);
  color: var(--light-gold);
  font-size: 1.7rem;
}

@media (min-width: 1200px) {
  .footerDetail span {
    display: block;
    padding-right: 10rem;
  }
}

@media (max-width: 992px) {
  .footerWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerCompanyDetails {
    align-items: center;
    text-align: center;
    padding-bottom: 0;
  }

  .stCol {
    width: 100%;
  }

  .ndCol {
    width: 100%;
    padding-top: 3rem;
  }

  .rdCol {
    width: 70%;
    padding-top: 3rem;
    padding-bottom: 10rem;
  }

  .footerCopyright {
    text-align: center;
  }
}
