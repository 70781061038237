#restructuringForm input,
#restructuringForm select,
#restructuringForm textarea,
#restructuringForm p {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  width: 100%;
}

#restructuringForm .help-text {
  margin-bottom: 3.2rem;
}
#restructuringForm .bold {
  margin-top: 2.6rem;
}

.error {
  color: red;
  font-size: 1.5rem;
}

.columns.large-12.medium-12,
.columns.large-6.medium-12,
#restructuringForm .columns.form-text {
  margin-bottom: 2.6rem;
}

.large-6 {
  width: 48%;
}
.form-checkboxes {
  margin-bottom: 2rem;
}

.checkbox-bold span {
  display: flex;
  align-items: center;
}

input[type="checkbox"] + span:before,
input[type="radio"] + span:before {
  content: " ";
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-right: 1rem;
  left: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
input[type="checkbox"],
input[type="radio"] {
  width: 0;
  height: 0;
  display: none;
}
input[type="checkbox"]:checked + span::before,
input[type="radio"]:checked + span::before {
  content: "X";
  font-weight: 800;
  color: #918a69;
}

@media (max-width: 992px) {
  .large-6 {
    width: 100%;
  }
}
