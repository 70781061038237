#indicesAndBaseRates .gridParent {
  grid-template-columns: 2fr repeat(7, 1fr);
  grid-template-rows: 2fr;
  grid-auto-rows: 1fr;
  padding: 0;
}

#indicesAndBaseRates .gridParent.nineCol {
  grid-template-columns: 2fr repeat(8, 1fr);
}
#indicesAndBaseRates .gridParent > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.2rem;
}

#indicesAndBaseRates .gridParent .i1,
#indicesAndBaseRates .gridParent.eightCol .i9,
#indicesAndBaseRates .gridParent.nineCol .i10 {
  justify-content: left;
}

#indicesAndBaseRates .gridParent:nth-of-type(2n):hover {
  background-color: #e6f0e3;
}

@media (min-width: 1069px) {
  #indicesAndBaseRates .gridParent:nth-of-type(1) {
    border-bottom: 1px solid var(--light-gray2);
  }
}

@media (max-width: 1068px) {
  #indicesAndBaseRates h3 {
    text-align: center;
  }

  #indicesAndBaseRates .gridTable {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  #indicesAndBaseRates .gridParent {
    grid-template-rows: 1fr;
  }

  #indicesAndBaseRates .gridTable .eightCol:nth-of-type(1),
  #indicesAndBaseRates .gridTable .nineCol:nth-of-type(1) {
    order: 0;
    grid-template-columns: repeat(1, 1fr);
    border-top: 1px solid var(--light-gray2);
    border-left: 1px solid var(--light-gray2);
  }

  #indicesAndBaseRates .gridTable .eightCol:nth-of-type(2),
  #indicesAndBaseRates .gridTable .nineCol:nth-of-type(2) {
    order: 1;
    grid-template-columns: repeat(1, 1fr);
    border-top: 1px solid var(--light-gray2);
    border-right: 1px solid var(--light-gray2);
  }

  #indicesAndBaseRates .gridParent > div {
    padding: 2rem;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--light-gray2);
  }

  #indicesAndBaseRates .gridParent:nth-of-type(odd) {
    border-right: 1px solid var(--light-gray2);
  }

  #indicesAndBaseRates .gridParent .i1,
  #indicesAndBaseRates .gridParent.eightCol .i9,
  #indicesAndBaseRates .gridParent.nineCol .i10 {
    justify-content: center;
  }
}
