#informationBmr .imageBackground {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../img/gnb-bmr1-bg-2400x900px.webp");
  display: flex;
  align-items: center;
}
#informationBmr .headerBg h1 {
  color: #fff;
  font-weight: 400;
}
#informationBmr h3 {
  margin: 4rem 0 2rem 0;
}

#informationBmr .gridParent {
  text-align: left;
  font-size: 1.8rem;
}

#EUordinanceTable .gridParent {
  grid-template-columns: repeat(4, 1fr);
}

#informationBmr .gridParent > div {
  padding-left: 2rem;
}

#remaningTable .gridParent {
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(7, 1fr); */
}

#bmrFaq .btnGold {
  padding-left: 8rem;
  padding-right: 8rem;
}

@media (max-width: 1077px) {
  #bmrFaq {
    text-align: center;
  }
}
